@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700&display=swap');

@font-face {
    font-family: Wigrum;
    src: url("../Fonts/Wigrum-Black.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: Wigrum;
    src: url("../Fonts/Wigrum-Medium.otf") format("opentype");
    font-weight: 700;
}

#root{
    --header-height: 6rem;
    --main-height: calc(100dvh - var(--header-height));
}

html,
body,
#root {
background-color: #FFFFFF;
overflow: hidden;
height: 100dvh;
width: 100vw;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

main{
    overflow: scroll;
    overflow-x: hidden;
    height: var(--main-height);
    padding: 0 15rem 1rem 15rem;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}
::-webkit-scrollbar-thumb {
    background: #88888880;
    border-radius: 0.5rem;
}
::-webkit-scrollbar-track {background: #f1f1f1;}
::-webkit-scrollbar-thumb:hover {background: #555;}

::selection {
    background: #88888840;
}

@media (max-width: 500px) {
    main{
        overflow-y: auto;
    }
}

@media (max-width: 1970px) {
    main{
        padding: 0 12rem 1rem 12rem;
    }
}

@media (max-width: 1450px) {
    main{
        padding: 0 6rem 1rem 6rem;
    }
}

@media (max-width: 930px) {
   main{
    padding: 0 2rem 2rem 2rem;
   }
}

/* OVERRIDE MAPBOX */
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right{
    display: none;
}

.feed-powered-by-es ,.feed-powered-by-es-slider-img{
    display: none;
}


.masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    width: auto;
}
.masonry-grid_column {
    background-clip: padding-box;
}

.carousel{
    width: 100% !important;
}

.carousel-item{
    padding: 0 0.4rem;
}

.carousel-item-mobile{
    padding: 30px;
    border-radius: 1rem;
    border: solid 8px #ffffff;
}

@media (max-width: 580px) {
    .notOnMobile{
        display: none;
    }

    .carousel{
        width: 100% !important;
        margin-left: auto !important;
    }
}

.maxLines{
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}